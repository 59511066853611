import React, { ReactNode, SyntheticEvent, useRef } from 'react'
import ReactMarkdown from 'react-markdown'
import { BaseStyles, Box, Container, Divider, ThemeUIStyleObject } from 'theme-ui'
import SanitizeHtml from '~/components/sanitize-html'
import HeadingV2 from '~/componentsV2/heading'
import { HubspotForm } from '~/utils/hubspotForm'

interface Props {
  title: string
  content: string
  hubspotFormId: string
  customSx?: ThemeUIStyleObject
  children?: ReactNode
}

const PageForm = ({ title, content, hubspotFormId, customSx, children }: Props) => {
  const scrollToRefObject = (ref: any) => {
    ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  const formTitleRef = useRef<HTMLHeadingElement>(null)

  const scrollToForm = (e: SyntheticEvent) => {
    e.preventDefault()
    scrollToRefObject(formTitleRef)
  }

  return (
    <Container>
      <BaseStyles
        sx={{
          maxWidth: 1380,
          margin: '50px 40px',
          ...customSx,
          h1: {
            mb: 30,
          },
          hr: {
            color: 'var(--theme-ui-colors-muted)',
          },
          form: {
            maxWidth: '1000px',
            margin: '0 auto',
            mt: 65,
            '& span': {
              color: '#595858 !important',
            },
            'input[type="text"], input[type="email"], input[type="tel"], select': {
              height: '81px !important',
              border: 'solid 1px #e5e5e5 !important',
              backgroundColor: '#f8f8f8 !important',
            },
            '& textarea': {
              height: '135px !important',
              border: 'solid 1px #e5e5e5 !important',
              backgroundColor: '#f8f8f8 !important',
            },
            '& select': {
              textTransform: 'uppercase',
              fontSize: '16px !important',
            },
          },
        }}
      >
        <HeadingV2 level="1">{title}</HeadingV2>
        <Divider />
        {content && (
          <Box>
            <ReactMarkdown
              source={content}
              disallowedTypes={['paragraph', 'image', 'link', 'strong', 'emphasis']}
              unwrapDisallowed
            />
          </Box>
        )}
        <HubspotForm formId={hubspotFormId} />
        {children}
      </BaseStyles>
    </Container>
  )
}

export default PageForm
