/** @jsx jsx **/
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { Fragment } from 'react'
import ReactMarkdown from 'react-markdown'
import { Box, Flex, Grid, Text, jsx } from 'theme-ui'
import { GatsbyImageType } from '~/@types/models'
import { hubspotFormIds } from '~/common/hubspot'
import Layout from '~/components/layout'
import HeadingV2 from '~/componentsV2/heading'
import theme from '~/gatsby-plugin-theme-ui'
import useMediaQuery from '~/hooks/use-media-query'
import PageForm from '~/templates/page-form'

interface QueryResult {
  pageData: {
    frontmatter: {
      title: string
      description: string
      headerVariant: string
      formTitle: string
      imageBottom: GatsbyImageType
      sections: Array<{
        title: string
        content: string
      }>
    }
    html: string
  }
}

const DesignServicesPage = () => {
  const { colors } = theme

  const query = useStaticQuery<QueryResult>(graphql`
    query {
      pageData: markdownRemark(frontmatter: { templateKey: { eq: "designServices" } }) {
        frontmatter {
          headerVariant
          title
          description
          formTitle
          imageBottom {
            ...heroImage
          }
          sections {
            title
            content
          }
        }
      }
    }
  `)

  const { title, description, sections, imageBottom, formTitle } = query.pageData.frontmatter

  const isDesktopOrLaptop = useMediaQuery({
    query: breakpoints => `(min-device-width: ${breakpoints[1]})`,
  })

  return (
    <Layout headerVariant="default" title={title} description={description}>
      <PageForm hubspotFormId={hubspotFormIds.designServices} title={title} content={description}>
        <Box margin="75px auto 0" sx={{ maxWidth: 960 }}>
          <HeadingV2
            level="2"
            sx={{
              mb: 100,
              textAlign: 'center',
              span: {
                textTransform: 'lowercase',
                fontStyle: 'italic',
              },
            }}
            dangerouslySetInnerHTML={{ __html: formTitle }}
          />
        </Box>
        <Grid columns={isDesktopOrLaptop ? ['3fr 3fr 3fr'] : '1fr'} sx={{ mb: 50 }}>
          {sections.map(section => (
            <Flex
              key={section.title}
              sx={{
                flexDirection: 'column',
                alignItems: 'center',
                padding: '0 25px 20px',
                '&:nth-child(even)': {
                  borderLeft: isDesktopOrLaptop ? `1px solid ${colors.zambezi}` : 'initial',
                  borderRight: isDesktopOrLaptop ? `1px solid ${colors.zambezi}` : 'initial',
                },
              }}
            >
              <HeadingV2 level="2">
                <ReactMarkdown
                  source={section.title}
                  disallowedTypes={['paragraph', 'image', 'link', 'strong', 'emphasis']}
                  unwrapDisallowed
                />
              </HeadingV2>

              <Text sx={{ textAlign: 'center' }}>
                <ReactMarkdown
                  source={section.content}
                  disallowedTypes={['paragraph', 'image', 'link', 'strong', 'emphasis']}
                  unwrapDisallowed
                />
              </Text>
            </Flex>
          ))}
        </Grid>
        <Box>
          <Img
            fluid={imageBottom?.childImageSharp.fluid}
            sx={{
              mt: 50,
              maxWidth: '100%',
            }}
          />
        </Box>
      </PageForm>
    </Layout>
  )
}

export default DesignServicesPage
